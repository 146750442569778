<template>
  <main class="container">
    <question-layout
      v-for="(question, questionKey) in section.enableSubsection
        ? subsection.questions
        : section.questions"
      :key="
        questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
      "
      :questionName="
        questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]
      "
      :question="
        questionKey.indexOf('questions.') == -1
          ? question
          : Object.assign({}, questions[`${questionKey.split('questions.')[1]}`], question)
      "
      :path="
        questionKey.indexOf('questions.') == -1
          ? section.enableSubsection
            ? `sections.${$route.params.section}.questions.${subsectionName}.questions.${questionKey}`
            : `sections.${$route.params.section}.questions.${questionKey}`
          : `sections.${$route.params.section}.questions.${questionKey.split('questions.')[1]}`
      "
      :responsePath="question.responsePath"
    />
    <footer>
      <div class="row">
        <div class="col-auto mr-auto" v-if="affichePrecedent">
          <router-link
            v-if="previousSection || previousSubsection"
            :to="{ name: 'Questionnaire', params: { section: previousSection, subsection: previousSubsection }, query: $route.query }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small">
              <span data-qa="precedent">PRÉCÉDENT</span>
            </button>
          </router-link>
        </div>
        <div
          class="col-auto"
          v-if="
            (isBtNext !== undefined && isBtNext === false) ||
            (isBtNext === undefined && (nextSection || nextSubsection))
          "
        >
          <router-link
            :to="{
              name: 'Questionnaire',
              params: { section: nextSection, subsection: nextSubsection },
              query: $route.query
            }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small" :class="{ 'opacity': !isValide }">
              <span data-qa="suivant">{{ section.nextButton ? section.nextButton : 'SUIVANT' }}</span>
            </button>
          </router-link>
        </div>
        <div class="col-auto" v-if="isBtNext !== undefined && isBtNext === true">
          <router-link
            :to="{
              name: 'Questionnaire',
              params: { section: nextSection, subsection: nextSubsection },
              query: $route.query,
            }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small" :class="{ 'opacity': !isValide }">
              <span data-qa="suivant">{{ section.nextButton ? section.nextButton : 'VALIDER' }}</span>
            </button>
          </router-link>
        </div>
      </div>
    </footer>
    <div class="mentions-legales">
      <div v-if="tarifRCVisible">
        (1) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au 31/12/2024 (en mode de
        paiement annuel) pour la souscription d'un contrat Groupama AFFINITES<span
          v-if="caisse !== 'GOC'"
          >, garantissant les responsabilités civiles « vie associative » et « occupant temporaire
          d’immeubles confiés » de l’Association. Conditions détaillées en demandant un devis
          d'assurance personnalisé au service Collectivités de Groupama.</span
        >.
      </div>
      <div v-if="tarifRCDABVisible">
        (2) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au 31/12/2024 (en mode de
        paiement annuel) pour la souscription d'un contrat Groupama AFFINITES<span
          v-if="caisse !== 'GOC'"
          >, garantissant une occupation temporaire de locaux d'une surface maximum de 50 m2, en
          incendie, événements naturels, dégâts des eaux et vandalisme, capital mobilier d'une
          valeur maximum de 10 000 € et franchise dommages de 348 €. Conditions détaillées en
          demandant un devis d'assurance personnalisé au service Collectivités de Groupama</span
        >.
      </div>
      <div v-if="tarifRCDABVisibleConfirmation">
        (3) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au 31/12/2024 (en mode de
        paiement annuel) pour la souscription d'un contrat Groupama AFFINITES<span
          v-if="caisse !== 'GOC'"
          >, garantissant :<br />
          - les responsabilités civiles « vie associative » et « occupant temporaire d’immeubles
          confiés » de l’Association. <br />
          - Une occupation permanente de locaux d’une surface maximum de 50 m² en incendie,
          évènements naturels, dégâts des eaux et vandalisme, capital mobilier d’une valeur maximum
          de 10 000 € et franchise dommages de 348 €. <br />
          Conditions détaillées en demandant un devis d’assurance personnalisé au service
          Collectivités de Groupama</span
        >.
      </div>
      <div v-if="tarifRCVisibleConfirmation">
        (3) Offre soumise à conditions. Tarif indicatif TTC valable jusqu'au 31/12/2024 (en mode de
        paiement annuel) pour la souscription d'un contrat Groupama AFFINITES<span
          v-if="caisse !== 'GOC'"
          >, garantissant les responsabilités civiles vie associative et occupant d'immeuble
          temporaire de l'Association.<br />
          Conditions détaillées en demandant un devis d'assurance personnalisé au service
          Collectivités Privées de Groupama</span
        >.
      </div>
    </div>
  </main>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { keys, slice, find, split, get, map, trim, forEach, filter } from 'lodash';
import { isRulesVisible, getProp, execQuestionDeepError } from '../utils/SurveyUtils';
import survey from '../../data/survey.json';
import store from '../store';
import { getError } from '../utils/validate';
import tracking from '../constants/tracking';

export default {
  computed: {
    caisse() {
      return this.$route.query.caisse.toUpperCase();
    },
    ...mapState({
      sectionList(state) {
        return state.survey.sections;
      },
      sectionNameList(state) {
        return filter(keys(state.survey.sections), name => name !== 'globals');
      },
      section(state) {
        return state.survey.sections[this.$route.params.section];
      },
      isBtNext(state) {
        let subsection =
          this.$route.params.subsection === undefined
            ? keys(this.section.questions)[0]
            : this.$route.params.subsection;
        return state.survey.sections[this.$route.params.section]?.questions[subsection]?.isBtNextClicked;
      },
      visibilityRulesBtNext(state) {
        let subsection =
          this.$route.params.subsection === undefined
            ? keys(this.section.questions)[0]
            : this.$route.params.subsection;
        return state.survey.sections[this.$route.params.section].questions[subsection]
          .visibilityRulesBtNext;
      },
      subsection(state) {
        let subsection =
          this.$route.params.subsection === undefined
            ? keys(this.section.questions)[0]
            : this.$route.params.subsection;
        return this.findVisibleSubsection(subsection, state, false);
      },
      subsectionName(state) {
        let subsection =
          this.$route.params.subsection === undefined
            ? keys(this.section.questions)[0]
            : this.$route.params.subsection;
        return this.findVisibleSubsection(subsection, state, true);
      },
      previousSection(state) {
        const sections = keys(state.survey.sections);
        let i = 1;
        // récupère la section précédente
        let previousSection =
          state.survey.sections[sections[sections.indexOf(this.$route.params.section) - i]];
        // si les visibility rules de la section ne sont pas respectées, on regarde la section d'avant ...
        while (
          previousSection !== undefined &&
          previousSection.visibilityRules !== undefined &&
          !isRulesVisible(previousSection.visibilityRules, state)
        ) {
          i++;
          previousSection =
            state.survey.sections[sections[sections.indexOf(this.$route.params.section) - i]];
        }
        // retourne la section en cours si il existe une sous-section sinon la dernière section visible
        // we need to verify if the previousSubsection includes in the sections subsection
        return this.section.enableSubsection &&
          this.previousSubsection &&
          keys(get(state, `survey.sections.${this.$route.params.section}`).questions).includes(
            this.previousSubsection
          )
          ? this.$route.params.section
          : sections[sections.indexOf(this.$route.params.section) - i];
      },
      previousSectionIfExist(state) {
        const sections = keys(state.survey.sections);
        let i = 1;
        // récupère la section précédente
        let previousSection =
          state.survey.sections[sections[sections.indexOf(this.$route.params.section) - i]];
        // si les visibility rules de la section ne sont pas respectées, on regarde la section d'avant ...
        while (
          previousSection !== undefined &&
          previousSection.visibilityRules !== undefined &&
          !isRulesVisible(previousSection.visibilityRules, state)
        ) {
          i++;
          previousSection =
            state.survey.sections[sections[sections.indexOf(this.$route.params.section) - i]];
        }
        return sections[sections.indexOf(this.$route.params.section) - i];
      },
      previousSubsection(state) {
        const subsections = keys(this.section.questions);
        if (this.section.enableSubsection) {
          // récupère la précédente sous-section (même si elle n'est pas visible)
          let havePreviousSubsection = subsections[subsections.indexOf(this.subsectionName) - 1];
          // si il y a au moins une sous-section, on cherche la précédente sous-section visible
          if (havePreviousSubsection) {
            return subsections[
              subsections.indexOf(
                this.findVisibleSubsection(havePreviousSubsection, state, true, true)
              )
            ];
          } else {
            // sinon on recupère la dernière sous section de la section precedente
            const previousSectionName = this.previousSectionIfExist;
            let previousSection = get(state, `survey.sections.${previousSectionName}`);
            if (previousSection && previousSection.enableSubsection) {
              return this.lastVisibleValidateSubsectionName(
                previousSection,
                keys(previousSection.questions),
                state
              );
            }
          }
          return undefined;
        }
        return undefined;
      },
      nextSection(state) {
        const sections = keys(state.survey.sections);
        let i = 1;
        // récupère la section suivante
        let nextSection =
          state.survey.sections[sections[sections.indexOf(this.$route.params.section) + i]];
        // si les visibility rules de la section ne sont pas respectées, on regarde la section d'après ...
        while (
          nextSection !== undefined &&
          nextSection.visibilityRules !== undefined &&
          !isRulesVisible(nextSection.visibilityRules, state)
        ) {
          i++;
          nextSection =
            state.survey.sections[sections[sections.indexOf(this.$route.params.section) + i]];
        }
        // retourne la section en cours si il existe une sous-section sinon la prochaine section visible
        return this.section.enableSubsection && this.nextSubsection
          ? this.$route.params.section
          : sections[sections.indexOf(this.$route.params.section) + i];
      },
      nextSubsection(state) {
        const subsections = keys(this.section.questions);
        if (this.section.enableSubsection) {
          // récupère la prochaine sous-section (même si elle n'est pas visible)
          let index = subsections.indexOf(this.subsectionName) + 1;
          // si il y a au moins une sous-section, on cherche la prochaine sous-section visible
          return index >= subsections.length
            ? undefined
            : subsections[
                subsections.indexOf(this.findVisibleSubsection(subsections[index], state, true))
              ];
        }
        return undefined;
      },
      questions(state) {
        return state.survey.questions;
      },
      affichePrecedent(state) {
        return this.$route.params.section !== 'confirmation';
      },
      isValide(state) {
        const sectionName = this.section.enableSubsection
          ? this.$route.params.section + '.questions.' + this.subsectionName
          : this.$route.params.section;
        // callback -> return true if we need to search error in survey or false if we have get error
        let callback = (q, p, rp) => {
          const isVisible = isRulesVisible(q.visibilityRules, state);
          if (isVisible) {
            const error = getError(getProp(q, rp, 'response'), q.rules);
            return !error;
          }
          return true;
        };
        let section = get(state, `survey.sections.${sectionName}`);
        // regarder les erreurs sur les questions de la section
        const arrayError = map(section.questions, (question, questionName) => {
          return !execQuestionDeepError(
            question,
            `sections.${sectionName}.questions.${questionName}`,
            question.responsePath,
            callback
          );
        });
        return find(arrayError, bool => bool === false) === undefined;
      },
      tarifRCVisible(state) {
        // console.log('value of visibilityRules : ', isRulesVisible(this.$store.survey.sections.devis.questions.tarif.visibilityRules, state));
        return (
          this.$route.params.section === 'devis' &&
          (this.$route.params.subsection === undefined || this.$route.params.subsection === 'tarif')
        );
      },
      tarifRCDABVisibleConfirmation(state) {
        // console.log('value of visibilityRules : ', isRulesVisible(this.$store.survey.sections.devis.questions.tarif.visibilityRules, state));
        let visibility = get(
          state,
          'survey.sections.confirmation.questions.tarifRCDab.visibilityRules'
        );
        return this.$route.params.section === 'confirmation' && isRulesVisible(visibility, state);
      },
      tarifRCVisibleConfirmation(state) {
        // console.log('value of visibilityRules : ', isRulesVisible(this.$store.survey.sections.devis.questions.tarif.visibilityRules, state));
        let visibility = get(
          state,
          'survey.sections.confirmation.questions.tarifRC.visibilityRules'
        );
        return this.$route.params.section === 'confirmation' && isRulesVisible(visibility, state);
      },
      tarifRCDABVisible(state) {
        // console.log('value of visibilityRules : ', isRulesVisible(this.$store.survey.sections.devis.questions.tarif.visibilityRules, state));
        let visibility = get(
          state,
          'survey.sections.devis.questions.tarif.questions.dommage.visibilityRules'
        );
        return this.$route.params.section === 'devis' && isRulesVisible(visibility, state);
      },
    }),
  },
  methods: {
    ...mapActions([
      'validateSection',
      'sendSurvey',
      'resetValue',
      'setValidateSection',
      'toggleBtNext',
      'setValueGlobal',
    ]),
    findVisibleSubsection(subsection, state, returnName = false, reverse = false) {
      let subsections = keys(this.section.questions);
      if (reverse) {
        subsections.reverse();
      }
      // on retire les sous-section déjà passé
      subsections = slice(subsections, subsections.indexOf(subsection));
      // cherche la prochaine sous-section visible
      let visibleSubsection = find(
        subsections,
        function (ss) {
          return isRulesVisible(this.section.questions[ss].visibilityRules, state);
        }
          .bind(this)
          .bind(state)
      );
      // retourne soit le nom soit la sous-section
      return returnName ? visibleSubsection : this.section.questions[visibleSubsection];
    },
    lastVisibleValidateSubsectionName(section, subsection, state) {
      let subsections = keys(section.questions).reverse();
      const retour = find(subsections, sub => {
        return section.questions[sub].isValidated;
      });
      return retour;
    },
    changeValueValidateSection(path, value) {
      this.setValidateSection({ path, value });
    },
    onRouteChange(to, from, next) {
      let tracker;
      // debugger;
      if (this.$store.state.survey.sections[to.params.section].tracking) {
        tracker = this.$store.state.survey.sections[to.params.section].tracking;
        let lastTrack = this.$store.state.survey.sections.globals.lastTrack;
        let etude;
        if (tracker.etude) etude = isRulesVisible(tracker.etude, this.$store.state);
        let tarif;
        if (tracker.tarif) tarif = isRulesVisible(tracker.tarif, this.$store.state);
        if (
          lastTrack !== tracker.track &&
          tracker.track !== this.$store.state.survey.sections.globals.secondLastTrack
        ) {
          this.setValueGlobal({ path: 'sections.globals.secondLastTrack', value: lastTrack });
          this.setValueGlobal({ path: 'sections.globals.lastTrack', value: tracker.track });
          console.log('track onRouteChange');
          tracking(tracker.track, etude, tarif, from.query.caisse);
        }
      }
      // on regarde la destination, si une section ou plus est sauté, il faut la/les valider
      let sections = this.sectionNameList;
      const valeurDest = sections.indexOf(trim(to.path, '/'));
      const valeurFrom = sections.indexOf(trim(from.path, '/'));
      const diffDestToFrom = valeurDest - valeurFrom;
      if (diffDestToFrom > 1) {
        // dans le cas ou il faut valider les sections entre dest et from
        forEach(slice(sections, valeurFrom + 1, valeurDest), sectionName =>
          this.changeValueValidateSection(`sections.${sectionName}`, true)
        );
      }
      // si il ya une sous section, on valide la sous section uniquement
      if (this.section.enableSubsection) {
        this.validateSection(from.params.section + '.questions.' + this.subsectionName).then(
          props => {
            if (this.subsection.isValidated === true) {
              if (!this.nextSubsection) {
                this.validateSection(from.params.section);
              }
              store.dispatch('sendSurvey', from.query);
              // this.sendSurvey();
              window.scroll(0, 0);
            }
            next(this.subsection.isValidated === true);
            window.scroll(0, 0);
          }
        );
      } else {
        // Si pas de prochaine sous section on valide la sous section puis la section
        this.validateSection(from.params.section).then(props => {
          if (this.section.isValidated === true) {
            store.dispatch('sendSurvey', from.query);
            // this.sendSurvey();
            window.scroll(0, 0);
          }
          next(this.section.isValidated === true);
          window.scroll(0, 0);
        });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.isSurveyCompleted) {
      return next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    // on check si le button suivant ne mene pas à la meme section (pré confirmation)
    // et le bouton doit etre cliquable !!
    if (this.isBtNext === false && this.isValide) {
      // on check si les visibility rules de button sont validé :
      const isBtNextValide = isRulesVisible(this.visibilityRulesBtNext, store.state);
      if (isBtNextValide) {
        let subsection =
          this.$route.params.subsection === undefined
            ? keys(this.section.questions)[0]
            : this.$route.params.subsection;
        return next(this.toggleBtNext({
          sectionPath: this.$route.params.section,
          subsectionPath: subsection,
        }) === true);
      }
    }
    let sections = this.sectionNameList;

    if (
      to.fullPath.includes(
        '/' + this.nextSection + (this.nextSubsection ? '/' + this.nextSubsection : '')
      )
    ) {
      // si on appui sur suivant
      this.onRouteChange(to, from, next);
    } else if (
      split(to.path, '/')[1] === 'identification' &&
      this.sectionList[sections[sections.indexOf(split(to.path, '/')[1])]] !== undefined &&
      (this.sectionList[sections[sections.indexOf(split(to.path, '/')[1])]].isValidated ||
        this.sectionList[sections[sections.indexOf(split(to.path, '/')[1])]].visibilityRules ===
          false)
    ) {
      // click header 'identification'
      console.log('on appuie sur la section identification');
      sections = slice(sections, sections.indexOf(split(to.path, '/')[1]) + 1);
      sections.forEach(section => {
        this.resetValue({ path: `sections.${section}`, value: survey.sections[section] });
      });
      next();
    } else if (
      to.path ===
      '/' +
        this.previousSection +
        (this.previousSubsection ? '/' + this.previousSubsection : 'false')
    ) {
      // si on appui sur précédent
      console.log('on appuie sur le bouton precedent');
      if (this.section.enableSubsection) {
        this.resetValue({
          path: `sections.${this.$route.params.section}.questions.${this.subsectionName}`,
          value: survey.sections[this.$route.params.section].questions[this.subsectionName],
        });
        console.log('value of section to clean in enableSubsection: ', this.previousSection);
        this.changeValueValidateSection(`sections.${this.previousSection}`, false);
      } else {
        this.resetValue({
          path: `sections.${this.$route.params.section}`,
          value: survey.sections[this.$route.params.section],
        }); // we need to clear is validate propertie of section courante
        console.log('value of section to clean : ', this.$route.params.section);
        this.changeValueValidateSection(`sections.${this.previousSection}`, false);
      }
      // si on passe de devis a un autre, il faut clear les données globals
      if (this.$route.params.section === 'devis') {
        this.resetValue({ path: 'sections.globals', value: survey.sections.globals });
      }
      next();
    } else if (
      this.sectionList[sections[sections.indexOf(split(to.path, '/')[1]) - 1]] !== undefined &&
      (this.sectionList[sections[sections.indexOf(split(to.path, '/')[1]) - 1]].isValidated ||
        this.sectionList[sections[sections.indexOf(split(to.path, '/')[1]) - 1]].visibilityRules ===
          false)
    ) {
      // si on appui sur une des sections du header
      // il faut supprimer toutes les reponses des sections d'après
      // ATTENTION si on arrive sur une sous section,
      // il faut supprimer que la sous section et non pas la setion entière
      const subsection = split(to.path, '/')[2];

      if (subsection) {
        // on reset la seciton qu'on quitte UNIQUEMENT
        sections = slice(sections, sections.indexOf(split(to.path, '/')[1]) + 1);
        sections.forEach(section => {
          this.resetValue({ path: `sections.${section}`, value: survey.sections[section] });
        });
        this.resetValue({
          path: `sections.${split(to.path, '/')[1]}.questions.${subsection}`,
          value: survey.sections[split(to.path, '/')[1]].questions[subsection],
        });
      } else {
        // si on est dans une section avec des subsections, il faut supprimer les sous sections suivantes
        const sectionToClean = split(to.path, '/')[1];
        // get all the subsections :
        let subsectionsAvailable = this.sectionList[sectionToClean].questions;
        let subsectionToClean = slice(Object.keys(subsectionsAvailable), 1);
        subsectionToClean.forEach(subsection => {
          this.resetValue({
            path: `sections.${sectionToClean}.questions.${subsection}`,
            value: survey.sections[sectionToClean].questions[subsection],
          });
        });

        // and clean all sections after this section
        sections = slice(sections, sections.indexOf(split(to.path, '/')[1]) + 1);
        console.log('value of sections : ', sections);
        sections.forEach(section => {
          this.resetValue({ path: `sections.${section}`, value: survey.sections[section] });
          this.changeValueValidateSection(`sections.${section}`, false);
        });

        // we need to clear is validate propertie of section courante
        this.changeValueValidateSection(`sections.${sectionToClean}`, false);

        // si on passe de devis ou confirmation a un autre, il faut clear les données globals
        if (
          this.$route.params.section === 'devis' ||
          this.$route.params.section === 'confirmation'
        ) {
          console.log('on clean les data globals');
          this.resetValue({ path: 'sections.globals', value: survey.sections.globals });
        }
      }
      next();
    } else if (
      to.path ===
      '/' + this.previousSection + (this.previousSubsection ? '/' + this.previousSubsection : '')
    ) {
      // si on appui sur précédent
      console.log('on appuie sur le bouton precedent');
      if (this.section.enableSubsection) {
        this.resetValue({
          path: `sections.${this.$route.params.section}.questions.${this.subsectionName}`,
          value: survey.sections[this.$route.params.section].questions[this.subsectionName],
        });
      } else {
        this.resetValue({
          path: `sections.${this.$route.params.section}`,
          value: survey.sections[this.$route.params.section],
        });
      }
      // si on passe de devis a un autre, il faut clear les données globals
      if (this.$route.params.section === 'devis') {
        this.resetValue({ path: 'sections.globals', value: survey.sections.globals });
      }
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteChange(to, from, next);
  },
};
</script>
<style lang="scss" scoped></style>
