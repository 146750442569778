<template>
  <div
    v-for="(label, value) in getOptions(question.options)"
    :key="value"
    :class="`${question.answerColClass || 'col'} field`"
  >

  <div v-if="question.serverPath === 'priseRDV'">
    <button
      :data-qa="questionName + label"
      type="button"
      :class="['btn btn-primary',question.fieldClass, { selected: value === response }]"
      @click="setValueAndNext(path, responsePath, value)"
    >
      <i
        v-if="question.fieldClass && question.fieldClass.includes('icon')"
        :class="value"
      ></i>
        <span>{{label}}</span>
    </button>
  </div>
  <div v-if="question.serverPath !== 'priseRDV'">
    <button
      :data-qa="questionName + label"
      type="button"
      :class="['btn btn-primary',question.fieldClass, { selected: value === response }]"
      @click="setValue({ path, responsePath, value })"
    >
      <i
        v-if="question.fieldClass && question.fieldClass.includes('icon')"
        :class="value"
      ></i>
        <span>{{label}}</span>
    </button>
  </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

import { keys, slice, find } from 'lodash';

import router from '../../router';

import { getOptions, getProp, isRulesVisible } from '../../utils/SurveyUtils';

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    responsePath: {
      type: String,
      required: false,
      default: '',
    },
    questionName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: mapState({
    response() {
      return getProp(this.question, this.responsePath, 'response');
    },
    sectionList(state) {
      return state.survey.sections;
    },
    section(state) {
      console.log('value of state : ', state);
      return state.survey.sections[this.$route.params.section];
    },
    isBtNext(state) {
      let subsection = (this.$route.params.subsection === undefined) ? keys(this.section.questions)[0] : this.$route.params.subsection;
      return state.survey.sections[this.$route.params.section].questions[subsection].isBtNextClicked;
    },
    visibilityRulesBtNext(state) {
      let subsection = (this.$route.params.subsection === undefined) ? keys(this.section.questions)[0] : this.$route.params.subsection;
      return state.survey.sections[this.$route.params.section].questions[subsection].visibilityRulesBtNext;
    },
    subsection(state) {
      let subsection = (this.$route.params.subsection === undefined) ? keys(this.section.questions)[0] : this.$route.params.subsection;
      return this.findVisibleSubsection(subsection, state, false);
    },
    subsectionName(state) {
      let subsection = (this.$route.params.subsection === undefined) ? keys(this.section.questions)[0] : this.$route.params.subsection;
      return this.findVisibleSubsection(subsection, state, true);
    },
    nextSection(state) {
      const sections = keys(state.survey.sections);
      let i = 1;
      // récupère la section suivante
      let nextSection = state.survey.sections[sections[sections.indexOf(this.$route.params.section) + i]];
      // si les visibility rules de la section ne sont pas respectées, on regarde la section d'après ...
      while (nextSection !== undefined && nextSection.visibilityRules !== undefined && !isRulesVisible(nextSection.visibilityRules, state)) {
        i++;
        nextSection = state.survey.sections[sections[sections.indexOf(this.$route.params.section) + i]];
      }
      // retourne la section en cours si il existe une sous-section sinon la prochaine section visible
      return (this.section.enableSubsection && this.nextSubsection) ? this.$route.params.section : sections[sections.indexOf(this.$route.params.section) + i];
    },
    nextSubsection(state) {
      const subsections = keys(this.section.questions);
      if (this.section.enableSubsection) {
        // récupère la prochaine sous-section (même si elle n'est pas visible)
        let index = subsections.indexOf(this.subsectionName) + 1;
        // si il y a au moins une sous-section, on cherche la prochaine sous-section visible
        return (index >= subsections.length) ? undefined : subsections[subsections.indexOf(this.findVisibleSubsection(subsections[index], state, true))];
      }
      return undefined;
    },
  }),
  methods: {
    getOptions,
    ...mapActions([
      'setValue',
    ]),
    findVisibleSubsection(subsection, state, returnName = false, reverse = false) {
      let subsections = keys(this.section.questions);
      if (reverse) {
        subsections.reverse();
      }
      // on retire les sous-section déjà passé
      subsections = slice(subsections, subsections.indexOf(subsection));
      // cherche la prochaine sous-section visible
      let visibleSubsection = find(subsections, function (ss) { return isRulesVisible(this.section.questions[ss].visibilityRules, state); }.bind(this).bind(state));
      // retourne soit le nom soit la sous-section
      return (returnName) ? visibleSubsection : this.section.questions[visibleSubsection];
    },
    setValueAndNext(path, responsePath, value) {
      this.setValue(({ path, responsePath, value }))
      .then(() => {
        router.push({
          name: 'Questionnaire',
          params: {
            section: this.nextSection,
            subsection: this.nextSubsection,
          },
          query: this.$route.query,
        });
      });
    },
  },
};
</script>
