export const CHECK_ERROR = 'CHECK_ERROR';
export const CHECK_ERROR_FP = 'CHECK_ERROR_FP';
export const SET_VALUE = 'SET_VALUE';
export const SET_VALUE_GLOBAL = 'SET_VALUE_GLOBAL';
export const SET_VALIDATE_SECTION = 'SET_VALIDATE_SECTION';
export const SET_VALUE_DATE = 'SET_VALUE_DATE';
export const RESET_QUESTION = 'RESET_QUESTION';
export const VALIDATE_SECTION = 'VALIDATE_SECTION';
export const FETCH_SURVEY = 'FETCH_SURVEY';
export const SEND_SURVEY = 'SEND_SURVEY';
export const RESET_VALUE = 'RESET_VALUE';
export const GET_POSTAL_CODE = 'GET_POSTAL_CODE';
export const TOGGLE_BT_NEXT = 'TOGGLE_BT_NEXT';

const actions = {
  checkError({ commit }, data) {
    commit(CHECK_ERROR, data);
  },
  checkErrorFP({ commit }, data) {
    commit(CHECK_ERROR_FP, data);
  },
  resetValue({ commit }, data) {
    commit(RESET_VALUE, data);
  },
  setValue({ commit }, data) {
    commit(SET_VALUE, data);
  },
  setValueGlobal({ commit }, data) {
    commit(SET_VALUE_GLOBAL, data);
  },
  setValidateSection({ commit }, data) {
    commit(SET_VALIDATE_SECTION, data);
  },
  setValueDate({ commit }, data) {
    commit(SET_VALUE_DATE, data);
  },
  resetQuestion({ commit }, data) {
    commit(RESET_QUESTION, data);
  },
  validateSection({ commit }, data) {
    commit(VALIDATE_SECTION, data);
  },
  fetchSurvey({ commit }, data) {
    commit(FETCH_SURVEY, data);
  },
  getAllPostalCode({ commit }) {
    commit(GET_POSTAL_CODE);
  },
  sendSurvey({ commit }, data) {
    commit(SEND_SURVEY, data);
  },
  toggleBtNext({ commit }, data) {
    commit(TOGGLE_BT_NEXT, data);
  },
};

export default actions;
