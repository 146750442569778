import { isNil } from 'lodash';

/* eslint-disable camelcase */

export const wa_gfr_init = {
  contenu: {
    fil_ariane: '',
    marche: 'particulier',
    region_enregistree: 'NAT',
    type_content: 'Tarificateur Affinite',
  },
  contact: {
    origine: 'site marchand',
  },
  tarificateur_affinite: {
    type_demande: '',
    tarif_en_ligne: '',
  },
};

if (__DEV__) {
  // implement _satellite for DEBUG purpose
  window._satellite = { track: null, pageBottom: null };
  window._satellite.track = (value) => {
    console.log('track', window.wa_gfr.contenu.fil_ariane);
    console.log('type_demande', window.wa_gfr.tarificateur_affinite.type_demande);
    console.log('tarif_en_ligne', window.wa_gfr.tarificateur_affinite.tarif_en_ligne);
  };
  window._satellite.pageBottom = (value) => { };
}

const tracking = (track, etude, tarif, trigramme) => {
  /*
    etape1 : pas de values
    etape2.1, etape2.2 : type_demande (Tarif en ligne ou Etude approfondie)
    etape2.3 : (tarif RC)
    etape2.4 : (tarif DAB)
    etape3dc : tarif_en_ligne ('RC' ou 'RC+DAB')
    etape3cc : demande Etude approfondie validée
    etape3cr : demande Etude approfondie refusée
  */
  console.log('DEBUG tracking', track, etude, tarif, trigramme);
  switch (track) {
  case 'init': // etape1
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 1 Identification et Activite',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape2.1':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 2.1 Description des risques',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: etude ? 'Etude approfondie' : 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape2.2':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 2.2 Description des risques - Coordonnees',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: etude ? 'Etude approfondie' : 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape2.3':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 2.3 Description des risques - Tarif RC',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape2.4':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 2.4 Description des risques - Tarif DAB',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape3.1':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 3.1 Tarif RC',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape3.2':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 3.2 Tarif DAB',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Tarif en ligne',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape3dc':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 3 Devis Confirmation',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Tarif en ligne',
        tarif_en_ligne: tarif ? 'RC+DAB' : 'RC',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape3cc':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 3 Contact Confirmation',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Etude approfondie',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  case 'etape3cr':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        fil_ariane: 'Tarificateur Affinite::Etape 3 Contact Refus',
        region_enregistree: (!isNil(trigramme)) ? trigramme : 'NAT',
        marche: 'particulier',
        type_content: 'Tarificateur Affinite',
      },
      tarificateur_affinite: {
        type_demande: 'Etude approfondie',
      },
    };
    window._satellite.track('tarificateuraffinite');
    window._satellite.pageBottom();
    break;
  default:
    console.log('Cas inconnu de tracker...');
    break;
  }
  console.log('=====================================');
  console.log(window.wa_gfr);
  console.log('=====================================');
};

export default tracking;
