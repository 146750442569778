<template>
  <div class="subheader">
    <div class="container">
      <div class="subheader-title">
        <div>{{title}}</div>
      </div>
      <div class="steps">
        <template v-for="(section, key, index) in sections"
        >
          <div
          v-if="!section.notShowHeader"
          :key="key"
          :data-qa="'step' + index"
          :class="['step', { 'validated': section.isValidated }, { 'current': isCurrent(section) }]">
            <div
              :disabled="!isValid(section)"
              class="icon-block"
            >
              <i/>
              <span>{{section.title}}</span>
            </div>
            <span class="line"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import { isRulesVisible } from '../../utils/SurveyUtils';
import { SECTIONS_NAME } from '../../constants/sections';

export default {
  computed: mapState({
    title: state => state.survey.title,
    isSurveyCompleted: state => state.isSurveyCompleted,
    sections: state => state.survey.sections,
  }),
  methods: {
    isValid(section) {
      // on doit checker si les visibilityRules de la section sont valider pour y accéder
      if (section.visibilityRules) {
        return isRulesVisible(section.visibilityRules, this.$store.state);
      }
      return true;
    },
    isCurrent(section) {
      // on doit checker si la section est selectioonné
      if (this.$route.params.section === 'confirmation') {
        return section.title === 'Votre tarif';
      }
      return SECTIONS_NAME[this.$route.params.section] === section.title;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
