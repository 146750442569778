 <template>
  <div class="container text-center">
    <div class="confirmation-block">
      <i class="icon-confirmation check" />
      <div class="confirm-message">
       Vous avez déjà répondu au questionnaire et nous vous en remercions.<br/>
      </div>
      <p>
      </p>
      <div class="confirm-question">
        Vous souhaitez être contacté(e) pour faire le point sur vos assurances ?
      </div>
    </div>
    <footer class="confirmation-footer">
      <div class="grid-center-noBottom">
        <a class="btn btn-secondary btn-big" target="_blank" href="">
          <i class="icon calendar" />
          <span>Je prends rendez-vous</span>
          <i class="arrow right" />
        </a>
      </div>
      <div class="cgu">
        <br>Les informations recueillies ont pour objectif de mieux vous connaître afin de vous conseiller et vous proposer les offres les plus adaptées à votre situation et vos besoins. Elles sont destinées à nos services internes et nos prestataires. Les réponses aux questions posées sont facultatives.
        Vous pouvez exercer vos droits sur vos données (droit d’accès, de rectification, de suppression, d’opposition, de limitation) en vous adressant par courrier postal au Délégué à la Protection des Données
        de Groupama Loire Bretagne, 23, Boulevard Solférino CS 51209 35012 Rennes cedex
      </div>
    </footer>
  </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
</style>
