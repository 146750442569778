<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div class="montant-mois">{{ calculMonth }}</div>
    <div class="montant-details">
      <div class="montant-details-mois">€/mois</div>
      <div>soit {{ calculYear }} €/an</div>
    </div>
    <div>{{ question.labelSuffix }}</div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
// import { getPath } from '../../utils/SurveyUtils';

import { round, replace, parseInt, get } from 'lodash';

import { isRulesVisible } from '../../utils/SurveyUtils';

export default {
  props: ['question', 'path', 'responsePath'],
  computed: {
    ...mapState({
      caisse(state) {
        return state.devisCaisse.caisse;
      },
      budget(state) {
        return parseInt(
          replace(
            state.survey.sections.risques.questions.questions56.questions.budget.response,
            ' ',
            ''
          )
        );
      },
      nbAdherents(state) {
        return parseInt(
          replace(
            state.survey.sections.risques.questions.questions56.questions.adherents.response,
            ' ',
            ''
          )
        );
      },
      surface(state) {
        return parseInt(
          replace(
            state.survey.sections.devis.questions.tarif.questions.details_biens.questions.surface
              .response,
            ' ',
            ''
          )
        );
      },
      contenu(state) {
        return parseInt(
          replace(
            state.survey.sections.devis.questions.tarif.questions.details_biens.questions.contenu
              .response,
            ' ',
            ''
          )
        );
      }
    }),
    calculMonth(state) {
      // console.log('Devis Caisse ===> ', this.caisse);
      // const budget = parseInt(replace(this.$store.state.survey.sections.risques.questions.questions56.questions.budget.response, ' ', ''));
      // const nbAdherents = parseInt(replace(this.$store.state.survey.sections.risques.questions.questions56.questions.adherents.response, ' ', ''));
      // let respCivile = (this.budget < 8000 && this.nbAdherents <= 50) ? 4.17 : 6.42;
      let { respCivile, dommages } = this.getTarif('mensuel');
      console.log('Tarif ==>', respCivile, dommages);
      // let dommages = 5.13;
      const nameDevis = get(
        this.$store.state.survey,
        'sections.globals.questions.calcul.nameDevis'
      );
      const retourMonth = replace(
        !this.question.respCivile
          ? dommages
          : this.question.dommages
          ? round(dommages + respCivile, 2)
          : respCivile,
        '.',
        ','
      );
      const retourMonthOld = get(
        this.$store.state.survey,
        'sections.globals.questions.calcul.response.calculMonth'
      );

      // if we dont want the infinite update loop (ty Vue.js !)
      // we need to check if the visibility rules of dab if true
      // on regarde si la valeur est enregistré
      // si pas de valuer sauvegarder, on sauvegarde respCivile dans globals
      // name devis peut etre identique mais valeur différente
      const isRCDAB = isRulesVisible(
        this.$store.state.survey.sections.devis.questions.tarif.questions.dommage.visibilityRules,
        this.$store.state
      );
      // we need to check if in confirmation
      let isConfirmation = this.path.includes('sections.confirmation');
      if (
        retourMonthOld === null ||
        retourMonthOld === undefined ||
        (nameDevis === 'RC' && retourMonthOld !== retourMonth && !isRCDAB && !isConfirmation)
      ) {
        this.setValueGlobal({
          path: 'sections.globals.questions.calcul.response.calculMonth',
          value: retourMonth
        });
      } else if (
        (isRCDAB &&
          !this.question.respCivile &&
          retourMonthOld !== replace(round(dommages + respCivile, 2), '.', ',')) ||
        (nameDevis === 'RC_DAB' &&
          isRCDAB &&
          !isConfirmation &&
          retourMonthOld !== replace(round(dommages + respCivile, 2), '.', ','))
      ) {
        // si on est dans le dab, et qu ela valeur est différente, on met a jour la valeur
        this.setValueGlobal({
          path: 'sections.globals.questions.calcul.response.calculMonth',
          value: replace(round(dommages + respCivile, 2), '.', ',')
        });
      }
      return retourMonth;
    },
    calculYear(state) {
      // const budget = parseInt(replace(this.$store.state.survey.sections.risques.questions.questions56.questions.budget.response, ' ', ''));
      // const nbAdherents = parseInt(replace(this.$store.state.survey.sections.risques.questions.questions56.questions.adherents.response, ' ', ''));
      // let respCivile = (this.budget < 8000 && this.nbAdherents <= 50) ? 50 : 77;
      let { respCivile, dommages } = this.getTarif('annuel');
      // let dommages = 61.56;
      const nameDevis = get(
        this.$store.state.survey,
        'sections.globals.questions.calcul.nameDevis'
      );
      const retourYear = replace(
        !this.question.respCivile
          ? dommages
          : this.question.dommages
          ? round(dommages + respCivile, 2)
          : respCivile,
        '.',
        ','
      );
      const retourYearOld = get(
        this.$store.state.survey,
        'sections.globals.questions.calcul.response.calculYear'
      );
      // on regarde si la valeur est enregistré
      // si pas de valuer sauvegarder, on sauvegarde respCivile dans globals
      const isRCDAB = isRulesVisible(
        this.$store.state.survey.sections.devis.questions.tarif.questions.dommage.visibilityRules,
        this.$store.state
      );
      let isConfirmation = this.path.includes('sections.confirmation');
      if (
        retourYearOld === null ||
        retourYearOld === undefined ||
        (nameDevis === 'RC_DAB' && !isRCDAB && !isConfirmation) ||
        (nameDevis === 'RC' && !isRCDAB && !isConfirmation && retourYearOld !== retourYear)
      ) {
        this.setValueGlobal({
          path: 'sections.globals.questions.calcul.response.calculYear',
          value: retourYear
        });
        this.setValueGlobal({ path: 'sections.globals.questions.calcul.nameDevis', value: 'RC' });
      } else if (
        (isRCDAB &&
          retourYearOld !== replace(round(dommages + respCivile, 2), '.', ',') &&
          nameDevis !== 'RC_DAB') ||
        (nameDevis === 'RC_DAB' &&
          isRCDAB &&
          !isConfirmation &&
          retourYearOld !== replace(round(dommages + respCivile, 2), '.', ','))
      ) {
        // si on est dans le dab, et qu ela valeur est différente, on met a jour la valeur
        this.setValueGlobal({
          path: 'sections.globals.questions.calcul.response.calculYear',
          value: replace(round(dommages + respCivile, 2), '.', ',')
        });
        this.setValueGlobal({
          path: 'sections.globals.questions.calcul.nameDevis',
          value: 'RC_DAB'
        });
      }
      return retourYear;
    }
  },
  methods: {
    ...mapActions(['setValueGlobal']),
    getTarif(typeTarif) {
      let respCivile = 0;
      let dommages = 0;
      switch (typeTarif) {
        case 'mensuel':
          // Calcul respCivile
          if (this.budget < 8000) {
            if (this.nbAdherents < 25) {
              respCivile = this.caisse.tarif_m1_lt8000_lt25;
            } else if (this.nbAdherents >= 25 && this.nbAdherents <= 50) {
              respCivile = this.caisse.tarif_m2_lt8000_btw_25_50;
            } else if (this.nbAdherents > 50 && this.nbAdherents <= 100) {
              respCivile = this.caisse.tarif_m3_lt8000_btw_51_100;
            }
          } else if (this.budget >= 8000) {
            if (this.nbAdherents < 25) {
              respCivile = this.caisse.tarif_m4_gte8000_lt25;
            } else if (this.nbAdherents >= 25 && this.nbAdherents <= 50) {
              respCivile = this.caisse.tarif_m5_gte8000_btw_25_50;
            } else if (this.nbAdherents > 50 && this.nbAdherents <= 100) {
              respCivile = this.caisse.tarif_m6_gte8000_btw_51_100;
            }
          }
          // Calcul dommages
          switch (this.caisse.trigramme) {
            case 'LBR':
            case 'TEST':
              if ((this.surface && this.surface <= 50) || (this.contenu && this.contenu <= 10000)) {
                dommages = this.caisse.dab_m1_lte25_lte5000;
              } else {
                dommages = null;
              }
              break;
            case 'GOC':
              if (this.surface && this.surface <= 25) {
                if (this.contenu && this.contenu <= 5000) {
                  dommages = this.caisse.dab_m1_lte25_lte5000;
                } else if (this.contenu && this.contenu > 5000) {
                  dommages = this.caisse.dab_m2_lte25_gt5000;
                }
              } else if (this.surface && this.surface > 25) {
                if (this.contenu && this.contenu <= 5000) {
                  dommages = this.caisse.dab_m3_gt25_lte5000;
                } else if (this.contenu && this.contenu > 5000) {
                  dommages = this.caisse.dab_m4_gt25_gt5000;
                }
              } else {
                dommages = null;
              }
              break;
            default:
              break;
          }
          break;
        case 'annuel':
          // Calcul respCivile
          if (this.budget < 8000) {
            if (this.nbAdherents < 25) {
              respCivile = this.caisse.tarif_a1_lt8000_lt25;
            } else if (this.nbAdherents >= 25 && this.nbAdherents <= 50) {
              respCivile = this.caisse.tarif_a2_lt8000_btw_25_50;
            } else if (this.nbAdherents > 50 && this.nbAdherents <= 100) {
              respCivile = this.caisse.tarif_a3_lt8000_btw_51_100;
            }
          } else if (this.budget >= 8000) {
            if (this.nbAdherents < 25) {
              respCivile = this.caisse.tarif_a4_gte8000_lt25;
            } else if (this.nbAdherents >= 25 && this.nbAdherents <= 50) {
              respCivile = this.caisse.tarif_a5_gte8000_btw_25_50;
            } else if (this.nbAdherents > 50 && this.nbAdherents <= 100) {
              respCivile = this.caisse.tarif_a6_gte8000_btw_51_100;
            }
          }
          // Calcul dommages
          switch (this.caisse.trigramme) {
            case 'LBR':
            case 'TEST':
              if ((this.surface && this.surface <= 50) || (this.contenu && this.contenu <= 10000)) {
                dommages = this.caisse.dab_a1_lte25_lte5000;
              } else {
                dommages = null;
              }
              break;
            case 'GOC':
              if (this.surface && this.surface <= 25) {
                if (this.contenu && this.contenu <= 5000) {
                  dommages = this.caisse.dab_a1_lte25_lte5000;
                } else if (this.contenu && this.contenu > 5000) {
                  dommages = this.caisse.dab_a2_lte25_gt5000;
                }
              } else if (this.surface && this.surface > 25) {
                if (this.contenu && this.contenu <= 5000) {
                  dommages = this.caisse.dab_a3_gt25_lte5000;
                } else if (this.contenu && this.contenu > 5000) {
                  dommages = this.caisse.dab_a4_gt25_gt5000;
                }
              } else {
                dommages = null;
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return { respCivile, dommages };
    }
  }
};
</script>
