<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <div v-sane-html="`${bloc}  <a href='tel:'>${NumTel}</a>`"/>
    <div> {{HoraireOuverture}}</div>
    <div class="small-text"> (Appel non surtaxé, coût d'un appel local.)</div>
  </div>
</template>
<script>

import { get } from 'lodash';

export default {
  props: ['question', 'path', 'responsePath'],
  computed: {
    NumTel(state) {
      return get(this.$store.state.devisCaisse, 'caisse.telephone_RC');
    },
    bloc(state) {
      return get(this.$store.state.survey, 'sections.confirmation.questions.Etape3Bis.bloc');
    },
    HoraireOuverture(state) {
      return get(this.$store.state.devisCaisse, 'caisse.horaires_RC');
    },
  },
};
</script>
