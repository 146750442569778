<template>
  <div v-if="question.tooltip" class="tooltip-container">
    <i class="fal fa-info-circle" />
    <span>{{question.tooltip}}</span>
  </div>
</template>
<script>

export default {
  props: ['question'],
};
</script>
