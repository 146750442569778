import axios from 'axios';
import { get, set, forEach, keys } from 'lodash';
import { set as setFP } from 'lodash/fp';
import moment from 'moment';

import {
  CHECK_ERROR_FP,
  SET_VALUE_GLOBAL,
  TOGGLE_BT_NEXT,
  SET_VALIDATE_SECTION,
  GET_POSTAL_CODE,
  CHECK_ERROR,
  SET_VALUE,
  SET_VALUE_DATE,
  RESET_QUESTION,
  VALIDATE_SECTION,
  FETCH_SURVEY,
  SEND_SURVEY,
  RESET_VALUE
} from './actions';

import { getError } from '../utils/validate';
import {
  getPath,
  getProp,
  execQuestionDeep,
  execSectionDeep,
  execSurveyDeep,
  isRulesVisible
} from '../utils/SurveyUtils';
import { getResponseFromServer, getResponseFromSurvey } from '../utils/ServerParser';

const mutations = {
  [CHECK_ERROR]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    // Use set (not setFP) to not re-create the question with the new error props (don't display the new error on user input)
    set(state.survey, getPath(path, responsePath, 'error'), getError(value, question.rules));
  },
  [CHECK_ERROR_FP]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    let survey = state.survey;
    survey = setFP(
      getPath(path, responsePath, 'error'),
      getError(value, question.rules),
      state.survey
    );
    if (getError(value, question.rules)) {
      survey = setFP('sections.risques.isValidated', false, survey);
    }
    state.survey = survey;
  },
  [SET_VALUE_DATE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    let valueDate = moment(value,'DD/MM/YYYY').format('DD/MM/YYYY');
    survey = setFP(getPath(path, responsePath, 'response'), valueDate, survey);
    survey = setFP(getPath(path, responsePath, 'responseFull'), value, survey);
    survey = setFP(
      getPath(path, responsePath, 'error'),
      getError(valueDate, question.rules),
      survey
    );
    state.survey = survey;
  },
  [RESET_VALUE]: (state, { path, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path), value, survey);
    state.survey = survey;
  },
  [SET_VALUE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    survey = setFP(getPath(path, responsePath, 'response'), value, survey);
    survey = setFP(getPath(path, responsePath, 'error'), getError(value, question.rules), survey);
    const sectionPath = path.split('.');
    // on récupère les sous sections
    const subsectionFromSection = get(
      state.survey,
      getPath(`${sectionPath[0]}.${sectionPath[1]}.questions`)
    );
    // on parcourt les sous sections pour mettre à false les isBtNextClicked à false.
    forEach(subsectionFromSection, (o, index) => {
      if (o.isBtNextClicked === true) {
        // on clear isBtNextClicked
        survey = setFP(
          getPath(`${sectionPath[0]}.${sectionPath[1]}.questions.${index}`, 'isBtNextClicked'),
          false,
          survey
        );
      }
    });
    state.survey = survey;
  },
  [SET_VALUE_GLOBAL]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path, responsePath), value, survey);
    state.survey = survey;
  },
  [TOGGLE_BT_NEXT]: (state, { sectionPath, subsectionPath }) => {
    let survey = state.survey;
    survey = setFP(
      getPath(`sections.${sectionPath}.questions.${subsectionPath}`, 'isBtNextClicked'),
      !get(
        state.survey,
        getPath(`sections.${sectionPath}.questions.${subsectionPath}`, 'isBtNextClicked')
      ),
      survey
    );
    state.survey = survey;
  },
  [SET_VALIDATE_SECTION]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = set(survey, getPath(path, 'isValidated'), value);
    state.survey = survey;
  },
  [RESET_QUESTION]: (state, { path, responsePath }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    execQuestionDeep(question, path, responsePath, (q, p, rp) => {
      survey = setFP(getPath(p, rp, 'response'), null, survey);
      survey = setFP(getPath(p, rp, 'error'), getError(null, q.rules), survey);
    });
    state.survey = survey;
  },
  [VALIDATE_SECTION]: (state, sectionName) => {
    let survey = state.survey;
    let sectionHasError = false;
    execSectionDeep(get(state, `survey.sections.${sectionName}`), sectionName, (q, p, rp) => {
      const isVisible = isRulesVisible(q.visibilityRules, state);
      if (isVisible) {
        const error = getError(getProp(q, rp, 'response'), q.rules);
        sectionHasError = sectionHasError || error;
        survey = setFP(getPath(p, rp, 'error'), error, survey);
        return true;
      }
      return false;
    });
    survey = setFP(`sections.${sectionName}.isValidated`, !sectionHasError, survey);
    state.survey = survey;
  },
  [FETCH_SURVEY]: (state, codeClient) => {
    state.surveyWithClientCode = true;
    let currentSession = sessionStorage.getItem('tarificateur-devis');
    // Si code client, on fait un appel a l'api
    // Sinon on recupère la current session si y'en a une ou on l'initialise
    if (codeClient) {
      axios
        .get(`${process.env.API_URL}/api/devis/${codeClient}`)
        .then(({ data }) => {
          let allSectionsValidated = true;
          let survey = state.survey;
          execSurveyDeep(
            survey,
            (s, sn) => {
              if (get(data, s.serverPathValidated) === true) {
                survey = setFP(`sections.${sn}.isValidated`, true, survey);
              } else {
                allSectionsValidated = false;
              }
            },
            (q, p, rp) => {
              const serverPath = getProp(q, 'serverPath', rp);
              if (serverPath) {
                survey = setFP(
                  getPath(p, rp, 'response'),
                  getResponseFromServer[q.questionType](data, serverPath, q.resetServerPath),
                  survey
                );
              }
            }
          );
          if (currentSession !== '[object Object]') {
            currentSession = JSON.parse(currentSession);
            state = currentSession;
          } else {
            state.survey = survey;
            if (allSectionsValidated) {
              state.isSurveyCompleted = true;
            }
          }
        })
        .catch(e => console.log(e));
    } else {
      if (currentSession !== '[object Object]') {
        currentSession = JSON.parse(currentSession);
        state = currentSession;
      } else {
      }
    }
  },
  [GET_POSTAL_CODE]: (state, codeClient) => {
    let survey = state.survey;
    state.surveyWithClientCode = true;
    // let currentSession = sessionStorage.getItem('tarificateur-devis');
    // appler axios get, pour aller chercher les postal code
    axios
      .get(`${process.env.API_URL}/api/postalCode/`)
      .then(({ data }) => {
        survey.sections.globals.cpVilles = data;
        state.survey = survey;
        // puis les stocker dans le state
      })
      .catch(e => console.log(e));
  },
  [SEND_SURVEY]: (state, objCaisse) => {
    let responses = {};
    execSurveyDeep(
      state.survey,
      (s, sn) => {
        if (s.serverPathValidated && get(state.survey, `sections.${sn}.isValidated`) === true) {
          responses = setFP(s.serverPathValidated, true, responses);
        }
      },
      (q, p, rp) => {
        const serverPath = getProp(q, 'serverPath', rp);
        if (serverPath && q.questionType && getResponseFromSurvey[q.questionType]) {
          const values = getResponseFromSurvey[q.questionType](
            state.survey,
            getPath(p, rp, 'response'),
            serverPath,
            q.resetServerPath
          );
          if (typeof values === 'object' && keys(values).length > 0) {
            forEach(values, (value, path) => {
              responses = setFP(path, value, responses);
            });
          } else {
            if (get(responses, serverPath) === undefined) {
              responses = setFP(serverPath, values, responses);
            }
          }
        }
      }
    );
    // si id défini, on update le devis, sinon on en créer un nouveau
    if (state.survey.sections.identification.questions.exportId.response) {
      axios
        .put(
          `${process.env.API_URL}/api/devis/${
            state.survey.sections.identification.questions.exportId.response
          }`,
          { data: responses, caisse: state.devisCaisse.caisse }
        )
        .then(({ data }) => {
          state.survey = setFP(
            'sections.identification.questions.exportId.response',
            data.data.devis_id,
            state.survey
          );
        })
        .catch(e => console.log(e));
    } else {
      axios
        .post(`${process.env.API_URL}/api/devis/`, { data: responses, query: objCaisse })
        .then(({ data }) => {
          if (data.data.devis_caisse) {
            state.devisCaisse = setFP('caisse', data.data.devis_caisse, state.devisCaisse);
          }
          state.survey = setFP(
            'sections.identification.questions.exportId.response',
            data.data.devis_id,
            state.survey
          );
        })
        .catch(e => console.log(e));
    }
  }
};

export default mutations;
